@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
}

body {
  border: 5px solid #1c4c82; /* Blue border of 10px thickness */
  box-sizing: border-box;
}

.clip-logo {
  position: relative;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    90% 100%,
    10% 100%
  ); /* Adjusted for smoother edges */
  /* border-radius: 0 0 50px 50px; */
  background-color: #1c4c82; /* Blue background for GPLAN */
  height: 100%;
}

.clicked {
  color: #1c4c82; /* Blue color for active link */
  text-decoration: underline; /* Underline for active link */
}
.policy {
  padding: 2rem;
}

.policy h2 {
  text-align: "left";
  font-family: "Bebas Neue";
  color: "#828282";
  font-size: 1.5rem;
  font-weight: 400;
  line-height: "normal";
  margin-bottom: "5px";
  color: #1c4c82;
}

.policy p {
  font-size: 1rem;
  text-align: "justify";
  font-family: "Montserrat";
  font-style: "normal";
  line-height: "20px";
  margin-bottom: "20px";
}
